import React from 'react'
import styled from 'styled-components'
import media from 'utils/media-queries'

import Header from 'components/header'
import Spirograph from 'components/spirograph'

const HeroSection = styled.section`
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`

function Hero() {
  return (
    <HeroSection>
      <Header />
    </HeroSection>
  )
}
export default Hero
